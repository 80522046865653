<template>
  <div>
    <BackCrumb />
    <h1 class="c-view-h1">{{$store.state.event.name}}</h1>
    <ui-toolbar :actions="toolbar"></ui-toolbar>
    <div class="">
      <ui-table :data="filtered" :columns="columns" :actions="actions" :pageSize="25" />
    </div>
  </div>
  <new-filter ref="newFilter" />
  <a :href="exportBlobURL" :download="exportName" target="_blank" ref="downloadHelper" class="download-helper"></a>
</template>

<script>

import NewFilter from "@/components/Admin/modals/NewFilter";
import BackCrumb from "../../../components/Admin/Event/BackCrumb";
import {getSID} from "@/store/auth";

import Papa from 'papaparse';

export default {
  name: "Attendees",
  components: {
    NewFilter,
    BackCrumb
  },
  data(){
    return {
      exportBlobURL: '',

      actions: [
        {
          icon: 'fa-edit',
          action: (attendee) => window.open(this.$env.whiteLabel.mailDomain + 'subscribermanager/subscribermanager/getUpdateSubscriber/'+ attendee.mwId +'?sid='+ getSID(), '_blank'),
          title: 'Bearbeiten'
        }
      ],
      toolbar: [
        {
          title: 'Filter',
          label: `<i class="far fa-file-import"></i> Import in ${this.$env.whiteLabel.mailProduct}`,
          component: 'button',
          type: 'blank',
          action: () => window.open(this.$env.whiteLabel.mailDomain + 'subscriberimport/subscriberimport/import1/import?sid='+ getSID())
        },
        {
          title: 'Filter',
          label: `<i class="far fa-filter"></i> Filter`,
          component: 'button',
          type: 'blank',
          action: () => this.$refs.newFilter.open()
        },
        {
          title: 'CSV Export',
          label: `<i class="far fa-file-export"></i> CSV Export`,
          component: 'button',
          type: 'blank',
          action: this.downloadExport
        }
      ]
    }
  },
  methods: {
    downloadExport() {
      if (this.exportBlobURL !== '') {
        window.URL.revokeObjectURL(this.exportBlobURL);
        this.exportBlobURL = '';
      }

      const csv = Papa.unparse(this.filtered.map((row) => {
        return this.columns.reduce((a, c) => {
          a[c.label] = row[c.prop];
          return a;
        }, {});
      }), {
        quotes: true,
        delimiter: ';',
        columns: this.columns.map(c => c.label)
      });

      const blob = new Blob([csv], {
        type: 'text/csv'
      });

      this.exportBlobURL = window.URL.createObjectURL(blob);

      this.$nextTick(() => {
        this.$refs.downloadHelper.click();
      });
    }
  },
  async mounted() {
    await this.$store.getSubscriberFields()
    await this.$store.getRecipients()
    await this.$store.getTickets()
    console.log(this.$store)
  },
  computed: {
    exportName() {
      return `${this.$store.state.event.slug}_attendees.csv`;
    },
    columns(){
      return [
      {
        prop: 'mwId',
        label: 'ID',
        width: 6
      },
      {
        prop: String(this.$store.state.subscriberFields.salutation_id),
        label: 'Anrede'
      },
      {
        prop: String(this.$store.state.subscriberFields.firstname_id),
        label: 'Vorname'
      },
      {
        prop: String(this.$store.state.subscriberFields.lastname_id),
        label: 'Nachname'
      },
      {
        prop: String(this.$store.state.subscriberFields.email_id),
        label: 'e-Mail'
      }
    ]},
    search: {
      get() {
        return this.$env.searchField
      },
      set(value) {
        this.$env.searchField = value
      }
    },
    richRecipients(){
      let ret = this.$store.state.recipients
      ret.map(r => {
        r.ticket = this.$store.state.tickets.find(t => Number(t.mwSubscriberId) === Number(r.mwId))
      })
      return ret
    },
    filtered() {
      let origin = [], filtered = []

      // if(!Array.isArray(this.$root.users)) return []
      origin = this.richRecipients

      // if (this.filters.length > 0) {
      //   origin = origin.filter(user => {
      //     let matches = 0
      //     this.filters.forEach(f => {
      //       let result = this.getOperator(f.name,f.operator).filter(this.$root.event, user, f.entity, f.value)
      //       if(result) matches++
      //       return result
      //     })
      //     if(this.filterMode === 'and' && matches === this.filters.length) return true
      //     if(this.filterMode === 'andOr' && matches > 0) return true
      //     if(this.filterMode === 'or' && matches === 1) return true
      //     return false
      //   })
      // }

      if (this.search == '') {
        return origin
      }
      if (this.search.length < 3) {
        return origin
      }
      const patt = new RegExp(this.search, "i")
      filtered = origin.filter(entry => {
        let found = false
        this.columns.forEach(c => found = patt.test(entry[c.prop]) ? true : found)
        console.log(found)
        return found
      })
      return filtered
    }
  }
}
</script>

<style scoped>
a.download-helper {
  display: none;
}
</style>
