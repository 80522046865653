<template>
  <ui-modal title="Neuen Filter anlegen" ref="modal" @confirm="confirm()">
    <p class="px-3 pb-2">
      Bitte geben Sie einen Titel an:
    </p>
    <p>
      <ui-form-input type="text" placeholder="Titel der Session" v-model="name"/>
    </p>
  </ui-modal>
</template>

<script>
export default {
  name: "NewFilter",
  data(){
    return {
      name: "Neuer Filter"
    }
  },
  methods: {
    async confirm(){
      return true
    },
    open(){
      this.$refs.modal.open()
    }
  }
}
</script>

<style scoped>

</style>